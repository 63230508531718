html {
    background: $background-color;
    position: relative;
    min-height: 100%;
}

body {
    color: $text-color;
    font-family: $font-family;
    font-size: $font-size;
    word-wrap: break-word;
    margin: 0 0 75px; /* bottom = footer height */
}

a {
    color: $link-color;
    text-decoration: none;
    &:hover {
        opacity: .8;
        text-decoration: underline;
    }
}

hr {
    border: 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid #fff;
    margin: 1em 0;
    &.with-margin {
        margin: $margin 0;
    }
    &.with-no-margin {
        margin: 0;
    }
}

input, select, textarea {
    border-radius: 0.3em;
    border: 1px solid $border-color;
    display: inline-block;
    padding: 0.5em 0.75em;
}

iframe, img, embed, object, video {
    max-width: 100%;
}

figcaption {
    text-align: center;
    font-style: italic;
}

figure {
    margin-top: 20px;
    margin-bottom: 20px;
}

figure img {
    margin: 5px auto 5px auto;
    display: block;
}
