.site-footer {
    border-top: 3px solid $brand-color;
    padding: $margin/2 0;
    color: lighten($text-color, 30%);
    text-align: center;
    font-size: 0.9em;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 75px;
    width: 100%;
    overflow: hidden;
}
