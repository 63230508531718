// typography.
$font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$font-size: 16px;

// source code typography.
$code-font-family: Consolas, Monaco, 'Andale Mono', monospace;
$code-font-size: 0.85em;

// vertical margin.
$margin: 25px;

// brand color.
//$brand-color: #f98752;
$brand-color: #055925;

// other major colors.
$site-title-color: rgba(#fff, .9);
$page-title-color: $brand-color;
$background-color: #fff;
$border-color: rgba(#000, .1);

// typography colors.
$text-color: #383838;
$link-color: darken($brand-color, 5%);
$blockquote-color: #777;
