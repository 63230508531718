$site-max-width: 1200px;
$mobile-width: 800px;
$side-padding: 10px;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.site-header {
    width: 100%;
    .inner {
        width: $site-max-width;
        margin: 0 auto;
        @media screen and (max-width: $site-max-width) {
            width: 100%;
            padding: 0 $side-padding;
        }
    }
}

.site-container {
    @extend .clearfix;
    width: $site-max-width;
    margin: 0 auto;
    @media screen and (max-width: $site-max-width) {
        width: 100%;
        padding: 0 $side-padding;
    }
    @media screen and (max-width: $mobile-width) {
        padding: 0;
    }
}

.site-content {
    width: 70%;
    float: left;
    @media screen and (max-width: $mobile-width) {
        width: 100%;
        padding: 0 $side-padding;
    }
}

.site-aside {
    padding-left: 50px;
    width: 30%;
    float: left;
    @media screen and (max-width: $mobile-width) {
        width: 100%;
        padding: 0 $side-padding;
    }
    .inner {
    }
}

.site-footer {
    width: 100%;
    .inner {
        padding: 0 $side-padding;
    }
}
